@import "base.scss";

.feature_card {
  border-radius: 8px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.05);
  padding: 30px 50px;
  .feature_card_img {
    width: 50%;
    margin-bottom: 26px;
  }
  .feature_card_text {
    color: #fff;
    font-size: 21px;
    line-height: 37.12px;
    letter-spacing: -0.64px;
    text-align: center;
    @media (max-width: 991px) {
      font-size: 12px;
    }
  }
}
