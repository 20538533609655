@import "base.scss";

.change_pass_modal {
  background-color: #fff;
  padding-top: 78px;
  padding-left: 91px;
  padding-right: 91px;
  padding-bottom: 29px;
  border-radius: 8px;
  box-shadow: 4px 2px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 50%;

  @media (max-width: 991px) {
    padding-top: 28px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 29px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  .change_pass_modal_title {
    margin-bottom: 40px;
  }

  .change_pass_modal_close {
    width: 46px;
    height: 46px;
    position: absolute;
    right: 32px;
    top: 21px;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 36px;
      height: 36px;
    }
  }

  .change_pass_modal_info_wrap {
    background-color: #f2f5ff;
    padding: 16px;
    margin-bottom: 14px;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .change_pass_modal_info_bold {
      margin-right: 17px;
      color: #212121;
      font-size: 14px;
      font-weight: 500;

      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
  }

  .change_pass_modal_sale_text {
    align-items: center;
    margin-bottom: 6px;

    .change_pass_modal_sale_bold {
      margin-right: 17px;
      color: #5f41b2;
      text-align: center;
      font-size: 50px;

      @media (max-width: 991px) {
        font-size: 15px;
      }
    }

    .change_pass_modal_sale_normal {
      color: #4c5563;
      font-size: 15px;
      padding-right: 10px;

      @media (max-width: 991px) {
        font-size: 12px;
      }

      @media (max-width: 767px) {
        font-size: 11px;
      }
    }
  }

  .change_pass_modal_input_warp {
    margin-top: 30px;

    .change_pass_modal_input {
      .change_pass_modal_input_item {
        .change_pass_modal_input_item_fee {
          margin-top: -20px;
          margin-bottom: 25px;
          color: #4c5563;
          font-size: 15px;

          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
      }

      .change_pass_modal_input_text {
        color: $blue;
        font-size: 14px;
        margin-bottom: 10px;

        @media (max-width: 991px) {
          font-size: 12px;
        }
      }

      .change_pass_modal_input_btn_wrap {
        .change_pass_modal_input_btn {
          margin-top: 30px;
          max-width: 140px;
          margin-left: 16px;
        }

        .change_pass_modal_input_btn_two {
          @extend .change_pass_modal_input_btn;
          background-color: #fff;
          border-radius: 26px;
          border: 1px solid #4c5563;
          color: #4c5563;
        }
      }
    }
  }
}
