@import "base.scss";

.dashboard_banner {
  align-items: center;
  border-radius: 8px;
  background: #f2f5ff;
  width: 100%;
  padding: 30px;
  margin-bottom: 18px;
  @media (max-width: 479px) {
    flex-direction: column-reverse;
  }
  .dashboard_banner_bold {
    color: $blue;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 991px) {
      font-size: 15px;
    }
    @media (max-width: 479px) {
      text-align: center;
      margin-top: 20px;
    }
  }
  .dashboard_banner_text {
    color: #4c5563;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    @media (max-width: 991px) {
      font-size: 12px;
    }
    @media (max-width: 479px) {
      text-align: center;
      margin-top: 10px;
    }
  }
}
