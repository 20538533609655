@import "base.scss";

.why_us {
  background: #143b76;
  padding: 120px 0px;
  @media (max-width: 991px) {
    padding: 80px 0px;
  }
  .why_us_heading {
    color: #fcfcfd;
    font-size: 32px;
    line-height: 37.12px;
    letter-spacing: -0.64px;
    margin-bottom: 18px;
    @media (max-width: 991px) {
      font-size: 18px;
    }
  }
  .why_us_card_wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 18px;
    align-items: stretch;
    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
