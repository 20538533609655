@import "base.scss";

.track_project_review {
  margin-top: 43px;
  width: 100%;
  overflow-x: scroll;

  .track_project_table {
    width: 100%;
    min-width: 600px;

    .track_project_table_head {
      border-radius: 6.436px;
      background: #f8f8f8;
      padding: 12px 19px;

      .track_project_table_head_item {
        color: #212121;
        font-size: 11.263px;
        // font-family: Inter;
        font-weight: 500;
        line-height: 16.09px;
        width: 150px;
        white-space: nowrap;
        @media (max-width: 991px) {
          width: 90px;
        }
      }
    }

    .track_project_table_body {
      padding: 12px 19px;
      cursor: pointer;
      transition: all 0.3s linear;
      border-bottom: 1px solid #dedede;
      text-decoration: none;
      &:hover {
        background: #f8f8f8;
      }
      .track_project_table_body_item {
        text-align: left;
        width: 150px;
        color: #4c5563;
        overflow-x: scroll;
        font-size: 11.263px;

        &::-webkit-scrollbar {
          background: transparent;
          width: 0;
          height: 0;
        }

        // font-family: Inter;
        font-weight: 400;
        line-height: 16.09px;
        white-space: nowrap;

        @media (max-width: 991px) {
          width: 90px;
        }
      }
    }

    .track_project_table_body_bg {
      background-color: #f8f8f8;
    }
  }
}
