@import "base.scss";

.dashboard_project {
  width: 100%;
  .dashboard_project_wrap {
    background: #f8f8f8;
    padding-left: 33px;
    padding-right: 33px;
    padding-top: 19px;
    padding-bottom: 29px;
    align-items: flex-start;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    .dashboard_project_btn_wrap {
      @media (max-width: 991px) {
        margin-bottom: 40px;
      }
      .dashboard_project_title {
        color: $blue;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 28px;
        @media (max-width: 991px) {
          font-size: 18px;
        }
      }
      .dashboard_project_btns {
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
        .dashboard_project_btn {
          background-color: #5f41b2;
          white-space: nowrap;
          margin-right: 15px;
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }
        .dashboard_project_btn_active {
          background-color: $blue;
        }
      }
    }
    .dashboard_project_organisation {
      .dashboard_project_organisation_title {
        color: #4277ff;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 8px;
        @media (max-width: 991px) {
          font-size: 12px;
        }
      }
      .dashboard_project_organisation_name {
        @extend .dashboard_project_organisation_title;
        color: #212121;
        margin-bottom: 0;
      }
    }
  }
}
