@import "base.scss";

.hero {
  background-color: #115ccd;
  padding-top: 185px;
  padding-bottom: 50px;
  .hero_wrap {
    @media (max-width: 991px) {
      flex-wrap: wrap;
      gap: 40px;
    }
    .hero_text_wrap {
      .hero_text_heading {
        color: #fff;
        font-family: DM Sans;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        max-width: 495px;
        margin-bottom: 18px;
        @media (max-width: 991px) {
          font-size: 30px;
        }
      }

      .hero_text {
        color: #fcfcfd;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.08px;
        max-width: 511px;
        margin-bottom: 36px;
        @media (max-width: 991px) {
          font-size: 15px;
        }
      }
      .hero_text_wrap_btn {
        .hero_text_btn {
          max-width: 170px;
          margin-right: 16px;
          padding: 12px 40px;
          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
        .hero_text_btn_two {
          @extend .hero_text_btn;
          background: #5f41b2;
        }
      }
    }
    .hero_img_wrap_anim {
      width: 55%;
      //   height: 390px;
      padding: 10px;

      @media (max-width: 991px) {
        width: 100%;
      }
      .hero_img_wrap {
        border-radius: 24px;
        background: rgba(255, 255, 255, 0.3);
        width: 100%;
        //   height: 390px;
        padding: 10px;

        .hero_img {
          width: 100%;
          height: 100%;
          border-radius: 12px;
          // object-fit: cover;
        }
      }
    }
  }
  .hero_marquee {
    margin-top: 100px;
    position: relative;
    .hero_marquee_grad {
      position: absolute;
      bottom: 0;
      //   left: 15%;
      right: 20%;
      height: 33%;
      width: 60%;
      background-color: rgba(255, 255, 255, 0.7);
      filter: blur(60px);
      @media (max-width: 991px) {
        display: none;
      }
    }
    .hero_marquee_head {
      color: #fcfcfd;
      font-size: 24px;
      font-weight: 500;
      line-height: 28.08px;
      margin-bottom: 46px;
    }
    .hero_marquee_wrap {
      .hero_logos {
        margin: 0px 42px;
        height: 50px;
        object-fit: contain;
      }
      .hero_logos_d {
        @extend .hero_logos;
        height: 30px;
      }
    }
  }
}
