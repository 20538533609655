@import "base.scss";

.text_list {
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
  }
  .text_list_heading_wrap {
    margin-bottom: 32px;
    .text_list_heading {
      @media (max-width: 991px) {
        text-align: center;
      }
    }
  }
  .text_list_wrap {
    margin-bottom: 25px;
    .text_list_tick_img {
      margin-right: 30px;
      margin-top: 8px;
      @media (max-width: 991px) {
        margin-right: 10px;
      }
    }
    .text_list_text {
      color: #667085;
      font-size: 18px;
      line-height: 28.08px;
      max-width: 419px;
      @media (max-width: 991px) {
        font-size: 14px;
        // text-align: center;
      }
    }
  }
  .text_list_btn {
    max-width: 189px;
    margin-top: 30px;
  }
}
