@import "base.scss";

.org_dash_home_project {
  width: 100%;
  .org_dash_home_project_heading {
    color: #143b76;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;

    @media (max-width: 991px) {
      font-size: 14px;
    }
  }
  .org_dash_home_project_wrap {
    border-radius: 8px;
    border: 1px solid #dedede;
    background: #fff;
    height: 196px;
    .org_dash_home_project_wrap_heading_wrap {
      border-bottom: 1px solid #dedede;
      padding: 17px;
      margin-bottom: 10px;
      .org_dash_home_project_wrap_heading {
        color: #212121;
        font-size: 15px;
        font-weight: 400;
        @media (max-width: 991px) {
          font-size: 12px;
        }
      }
    }
    .org_dash_home_project_wrap_heading_item {
      padding: 10px;
      align-items: center;
      .org_dash_home_project_wrap_heading {
        color: #212121;
        font-size: 12px;
        font-weight: 400;
        width: 45%;
        @media (max-width: 991px) {
          font-size: 12px;
        }
      }
    }
    .org_dash_home_project_empty {
      height: 70%;
      .org_dash_home_project_empty_text {
        color: #212121;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        text-decoration: none;
      }
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
  }
  .org_dash_home_project_empty_notallowed {
    cursor: not-allowed;
  }
}
