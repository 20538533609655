@import "base.scss";

.step_card {
  width: 100%;
  height: 230px;
  background: #e5f3ff;
  .step_card_img {
    width: 100%;
    height: 148px;
    object-fit: contain;
    @media (max-width: 767px) {
      width: 70%;
    }
  }
  .step_card_text {
    max-width: 240px;
    text-align: center;
    color: #212529;
    font-size: 18px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}
