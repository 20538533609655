@import "base.scss";

.track_proj_progress {
  width: 100%;
  .track_proj_progress_bar_wrap {
    position: relative;
    justify-content: center;
    width: 80%;
    @media (max-width: 991px) {
      width: 78%;
    }
    .track_proj_progress_bar {
      position: absolute;
      border-radius: 18px;
      background-color: #dedede;
      height: 8px;
      width: 100%;
    }
    .track_proj_progress_bar_two {
      @extend .track_proj_progress_bar;
      background-color: $blue;
    }
  }
  .track_proj_progress_text {
    color: #4c5563;
    font-size: 11px;
    line-height: 23.968px;
    @media (max-width: 991px) {
      font-size: 10px;
    }
  }
}
