@import "base.scss";

.document_list_wrap {
  width: 100%;
  .document_list {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    grid-gap: 40px;
    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      justify-items: center;
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
    }
    .document_list_item {
      position: relative;
      max-width: 250px;
      width: 100%;
      // background-position: 100% 100%;
      .document_list_item_bg {
        width: 100%;
      }
      .document_list_item_optn {
        position: absolute;
        top: 5%;
        right: 10%;
        background-color: #dedede;
        border-radius: 50%;
        padding: 2px;
        cursor: pointer;
      }
      .document_list_item_file {
        position: absolute;
        left: 8%;
        top: 20%;
        width: 82%;

        .document_list_item_file_name {
          color: #212121;
          font-size: 14px;
          font-weight: 500;
          white-space: nowrap;
          overflow-x: scroll;
          &::-webkit-scrollbar {
            width: 0.5px;
          }
          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
        .document_list_item_file_time {
          color: #4c5563;
          font-family: DM Sans;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          margin-bottom: 10px;
          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
        .document_list_item_file_img_wrap {
          width: 100%;
          // background-color: #dedede;
          border-radius: 8px;
          .document_list_item_file_img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
  .document_shimmer{
    width: 100%;
  }
}
