@import "base.scss";

.get_started_card {
  position: relative;
  padding: 30px 15px;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  .get_started_card_number {
    position: absolute;
    top: 20px;
    right: 40px;
    color: #212529;
    font-size: 32px;
    font-weight: 500;
    line-height: 37.12px;
    letter-spacing: -0.64px;
    @media (max-width: 991px) {
      font-size: 24px;
    }
  }
  .get_started_card_img {
    width: 50%;
    object-fit: contain;
    margin-bottom: 14px;
  }
  .get_started_card_heading {
    color: #212121;
    text-align: center;
    font-size: 24px;
    line-height: 37.12px;
    letter-spacing: -0.64px;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 18px;
    }
  }
  .get_started_card_text {
    color: #4c5563;
    text-align: center;
    font-size: 18px;
    line-height: 28.08px;
    @media (max-width: 991px) {
      font-size: 14px;
    }
  }
}
