// Shimmer.scss
.shimmer-wrapper {
    // Use relative positioning to contain the shimmer element
    position: relative;
    overflow: hidden;
    background-color: #d6d7db;
    border-radius: 10px;
}

.shimmer {
    // Use absolute positioning to cover the entire wrapper
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    // Use a linear gradient as the background color
    // The gradient has three color stops: transparent, white, and transparent
    // The white color stop is at the center of the gradient
    background: linear-gradient(to right,
            transparent,
            rgba(255, 255, 255, 0.6),
            transparent);

    // Use a transform to skew the gradient by -45 degrees
    transform: skewX(-45deg);

    // Use an animation to move the gradient from left to right
    // The animation duration is 1.5 seconds and repeats infinitely
    animation: shimmer 1.5s infinite;

    // Define the keyframes for the shimmer animation
    @keyframes shimmer {

        // At the start of the animation, move the gradient to -100% of the wrapper width
        // This means the gradient is completely off-screen on the left side
        0% {
            transform: translateX(-100%) skewX(-45deg);
        }

        // At the end of the animation, move the gradient to +100% of the wrapper width
        // This means the gradient is completely off-screen on the right side
        100% {
            transform: translateX(100%) skewX(-45deg);
        }
    }
}