@import "base.scss";

.custom_proj_select {
  width: 100%;
  position: relative;
  .custom_proj_select_title {
    font-size: 15px;
    color: $blue;
    margin-bottom: 8px;

    @media (max-width: 991px) {
      font-size: 12px;
    }
  }
  .custom_proj_select_item {
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid #a1a4b1;
    transition: all 0.3s linear;
    &:hover {
      border: 1px solid #115ccd;
    }
  }
  .custom_proj_select_options {
    position: absolute;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(33, 33, 33, 0.25);
    width: 100%;
    top: 90px;
    opacity: 1;
    transition: all 0.3s linear;
    z-index: 3;
    .custom_proj_select_option {
      @extend .custom_proj_select_item;
      border: 1px solid transparent;
    }
  }
  .custom_proj_select_options_close{
    @extend .custom_proj_select_options;
    opacity: 0;
    z-index: -10;
    // display: none;
  }
  .custom_proj_select_loading {
    position: absolute;
    border-radius: 4px;
    transition: all 0.3 ease-in-out;
    width: 100%;
    height: 57%;
    top: 44%;
    left: 0;
  }
}

.custom_proj_select_value {
  width: 100%;
  .custom_proj_select_value_text {
    margin-right: 8px;
    .bold {
      width: 80px;
    }
  }

  .custom_proj_select_item_img {
  }
}
