@import "base.scss";

.staff_banner {
  padding: 40px;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  @media (max-width: 991px) {
    padding: 20px;
    gap: 30px;
  }
  .staff_banner_name_wrap {
    align-items: center;
    .staff_banner_name {
      margin-left: 10px;
      color: $blue;
      font-size: 24px;
      font-weight: 500;
      @media (max-width: 991px) {
        font-size: 15px;
      }
    }
    .staff_banner_img {
      width: 80px;
      height: 80px;
      border: 3px solid #dcdddf;
      border-radius: 50%;
      @media (max-width: 991px) {
        width: 50px;
        height: 50px;
      }
    }
  }
  .staff_banner_title_wrap{
    .staff_banner_title{
        margin-bottom: 5px;
    }
    .staff_banner_val{
        color: #212121
    }
  }
}
