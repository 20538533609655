@import "base.scss";

.eco_system {
  flex-wrap: wrap;
  gap: 20px;
  padding: 100px 0px;
  .eco_system_list {
    width: 45%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .eco_system_anim_wrap {
    width: 45%;
    @media (max-width: 767px) {
      width: 100%;
    }
    .eco_system_anim_circle {
      width: 300px;
      height: 300px;
      border: 3px solid linear-gradient(to bottom, #cfcfff, #a4a4ff);
      border-radius: 50%;
      border: 3px solid #cfcfff;
      position: relative;
      @media (max-width: 767px) {
        width: 250px;
        height: 250px;
      }
      .eco_system_anim_circle_two {
        width: 200px;
        height: 200px;
        border: 3px solid (to bottom, #cfcfff, #a4a4ff);
        border-radius: 50%;
        border: 3px solid #a4a4ff;
        position: relative;
        @media (max-width: 767px) {
          width: 150px;
          height: 150px;
        }
        .orbittwo {
          width: 60%;
          object-fit: contain;
        }
        .orbitthree {
          position: absolute;
          z-index: 2;
          width: 35%;
          left: 60%;
          bottom: 0;
          animation: point 1s linear infinite;
        }
        .orbitseven {
          position: absolute;
          left: 0%;
          top: 0;
          width: 30px;
          height: 30px;
          animation: move 5s linear infinite;
          offset-rotate: auto;
          opacity: 0;
          offset-path: path(
            "M 100,0 A 100,100 0 1 1 100,200 A 100,100 0 1 1 100,0"
          );

          @media (max-width: 767px) {
            offset-path: path("M 75,0 A 75,75 0 1 1 75,150 A 75,75 0 1 1 75,0");
          }
        }
        .orbitfive {
          @extend .orbitseven;
          animation: move 5s 3s linear infinite;
          // animation-direction: reverse;
          width: 20px;
          height: 20px;
        }
      }
      .orbit {
        position: absolute;
        left: 0%;
        top: 0;
        width: 40px;
        height: 40px;
        animation: move 15s linear infinite;
        offset-rotate: auto;
        opacity: 0;
        offset-path: path(
          "M150,0 C67,0 0,67 0,150 C0,233 65,300 150,300 233,300 300,233 300,150 C300,67 233,0 150,0 Z"
        );
        @media (max-width: 767px) {
          offset-path: path(
            "M 125,0 A 125,125 0 1 1 125,250 A 125,125 0 1 1 125,0"
          );
          width: 25px;
          height: 25px;
        }
      }
      .orbiteight {
        @extend .orbit;
        bottom: 0%;
        animation: move 15s 3s linear infinite;
        // animation-direction: reverse;
        width: 55px;
        height: 55px;

        @media (max-width: 767px) {
          width: 30px;
          height: 30px;
        }
      }

      .orbitsix {
        @extend .orbit;
        animation: move 15s 7s linear infinite;
        width: 45px;
        height: 45px;

        @media (max-width: 767px) {
          width: 28px;
          height: 28px;
        }
      }
      .orbitfour {
        @extend .orbit;
        animation: move 15s 11s linear infinite;
        // animation-direction: reverse;
        width: 65px;
        height: 65px;
        @media (max-width: 767px) {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

// /* Define the name and keyframes of the animation */
@keyframes move {
  from {
    offset-distance: 0%;
    opacity: 1;
    /* Start from 0% of the path length */
  }

  to {
    offset-distance: 100%;
    opacity: 1;
    /* End at 100% of the path length */
  }
}
@keyframes point {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
