@import "base.scss";

.pagination {
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 767px) {
    row-gap: 20px;
  }
  .pagination_wrap {
    margin-right: 40px;
    .pagination_active {
      width: 32px;
      height: 32px;
      border-radius: 3px;
      background: #dedede;
      margin-right: 10px;
      cursor: pointer;
      border: 0.804px solid #dedede;
    }

    .pagination_inactive {
      @extend .pagination_active;
      background: #fff;
    }
    .pagination_dots_wrap{
      margin-right: 10px;
      .pagination_dots{
        width: 7px;
        height: 7px;
        background: #7A7A7A;
        border-radius: 50%;
        margin: 0 2px;
      }
    }
  }
  .pagination_num {
    margin-right: 40px;
    white-space: nowrap;
    padding-bottom: 5px;
  }
  .pagination_control {
    .pagination_control_item {
      border-radius: 3px;
      background: #d0ddff;
      width: 32px;
      height: 32px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
