$blue: #143b76;
$purple: #5f41b2;

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mb_10 {
  margin-bottom: 10px;
}
.mt_10 {
  margin-top: 10px;
}

.flex {
  display: flex;
}

.text {
  color: #212121;
  font-size: 15px;
  line-height: 150%;

  @media (max-width: 991px) {
    font-size: 12px;
  }
}

.sub_heading {
  color: #143b76;
  font-size: 24px;
  font-weight: 500;
  @media (max-width: 991px) {
    font-size: 18px;
  }
}

.col {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.link {
  text-decoration: none;
}

.spinner {
  height: 50vh;
}

// Auth
.auth {
  width: 100%;
  height: 110vh;

  .auth_left {
    width: 50%;

    @media (max-width: 991px) {
      display: none;
    }
  }

  .auth_right {
    width: 50%;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    padding-top: 100px;

    @media (max-width: 991px) {
      width: 100%;
      padding-top: 120px;
    }
  }
}

.auth_form {
  width: 100%;
  max-width: 461px;

  .auth_form_title {
    color: $blue;
    width: 100%;
    font-size: 32px;
    margin-bottom: 9px;

    @media (max-width: 991px) {
      font-size: 25px;
    }
  }

  .auth_form_sub_title {
    @extend .auth_form_title;
    font-size: 17px;
    margin-bottom: 42px;
    color: #4c5563;

    @media (max-width: 991px) {
      font-size: 15px;
    }
  }

  .auth_button_wrap {
    margin-top: 30px;
  }
  .footer_terms {
    padding: 10px 0px;
    color: #605f5f;
    font-size: 12px;
    line-height: 28.08px;
    bottom: 0;
    margin-bottom: -50px;

    @media (max-width: 991px) {
      font-size: 10px;
    }
  }
}

.dash_pad {
  padding: 30px;
  width: 100%;
  @media (max-width: 991px) {
    padding: 18px;
  }
}

.input_error {
  color: #ff0707;
  font-size: 12px;
  margin-top: 5px;
  letter-spacing: 1px;
  @media (max-width: 991px) {
    font-size: 11px;
  }
}

.grad_text {
  color: #212529;
  font-size: 32px;
  line-height: 37.12px;
  letter-spacing: -0.64px;
  span {
    background: linear-gradient(159deg, #115ccd 0%, #9c8bfb 81.65%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    line-height: 37.12px;
    letter-spacing: -0.64px;
  }
  @media (max-width: 991px) {
    font-size: 20px;
  }
}
