@import "base.scss";

.get_started {
  padding: 0px 0px 100px 0px;
  .grad_text {
    margin-bottom: 60px;
  }
  .get_started_wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 19px;
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 467px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .get_started_item {
    }
  }
  .get_started_btn {
    background: $purple;
    margin-top: 60px;
    max-width: 200px;
    width: 100%;
    padding: 12px 40px;
  }
}
