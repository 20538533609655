@import "base.scss";

.registry_details {
  padding-top: 100px;
  .registry_details_overview {
    // margin-top: 40px;
    .registry_details_overview_head {
      color: #143b76;
      font-size: 20px;
      margin-bottom: 11px;
      @media (max-width: 991px) {
        font-size: 15px;
      }
    }
    .registry_details_overview_wrap {
      flex-wrap: wrap;
      padding: 24px;
      border-radius: 8px;
      border: 1px solid #dedede;
      margin-bottom: 30px;
      @media (max-width: 991px) {
        gap: 20px;
      }
      .registry_details_overview_text_wrap {
        gap: 30px;
        .registry_details_overview_text {
          .registry_details_overview_title {
            color: #4c5563;
            font-size: 16px;
            line-height: 160%;
            margin-bottom: 14px;
            @media (max-width: 991px) {
              font-size: 13px;
            }
          }
          .registry_details_overview_value {
            color: #5f41b2;
            font-size: 20px;
            font-weight: 600;
            line-height: 23.968px;
            @media (max-width: 991px) {
              font-size: 15px;
            }
          }
          .registry_details_overview_title_two {
            @extend .registry_details_overview_title;
          }
          .registry_details_overview_value_two {
          }
        }
      }
    }
  }
  .registry_details_tab {
    margin-top: 21px;

    .registry_details_tab_item {
      color: #4c5563;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      line-height: 19.307px;
      border-bottom: 1px solid #d3d4d8;
      width: 50%;
      padding-bottom: 8px;
      cursor: pointer;

      @media (max-width: 991px) {
        font-size: 11px;
      }
    }

    .registry_details_tab_item_active {
      @extend .registry_details_tab_item;
      color: #115ccd;
      border-bottom: 2px solid #4277ff;
    }
  }
}
