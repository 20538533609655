@import "base.scss";
.upload_document {
  width: 100%;
  height: 140vh;
  padding: 30px;
  .upload_document_wrap {
    border-radius: 8px;
    border: 1px solid #a1a4b1;
    padding-left: 72px;
    padding-right: 72px;
    padding-bottom: 88px;
    padding-top: 59px;
    max-width: 725px;
    @media (max-width: 991px) {
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
      padding-top: 25px;
    }
    .upload_document_title {
      color: $blue;
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 60px;
      @media (max-width: 991px) {
        font-size: 24px;
      }
    }
    .upload_document_text {
      color: #4c5563;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      margin-bottom: 40px;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
    .upload_document_btn {
      margin-top: 40px;
    }
  }
  .admin_info_left_float {
    position: absolute;
    background: #0057ff;
    filter: blur(100px);
    height: 124px;
    width: 128px;
    right: 82px;
    bottom: 100px;
    border-radius: 50%;
    animation: floatAnimationinfo 15s infinite ease-in-out;
  }

  .admin_info_left_float_one {
    @extend .admin_info_left_float;
    left: 80px;
    bottom: 50px;
    background: #00ffc2;
    animation: floatAnimationinfo_one 10s infinite ease-in-out;
  }
}

// Animations
@keyframes floatAnimationinfo {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-70vh);
  }

  100% {
    transform: translate(0%);
  }
}

@keyframes floatAnimationinfo_one {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-50vw);
  }

  100% {
    transform: translateY(0%);
  }
}
