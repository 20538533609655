@import "base.scss";

.dashboard_notification {
  .dashboard_notification_wrap {
    width: 100%;
    align-items: flex-start;
    .notification-box-1 {
      max-width: 1052px;
      width: 100%;
      max-height: 357px;
      height: 100%;
      border-radius: 10px;
      border: 1px solid #dedede;
      background: #fff;
      display: flex;
      margin: 30px auto;
      justify-content: space-around;
      margin-bottom: 5px;
      @media (max-width: 768px) {
        width: 90%;
      }
    }
    .notification-content-1 {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      // align-items: center;
      gap: 15px;
    }
    .notification-box {
      max-width: 1052px;
      width: 100%;
      // max-height: 205px;
      height: 100%;
      border-radius: 10px;
      border: 1px solid #dedede;
      background: #fff;
      display: flex;
      margin: 20px auto;
      justify-content: space-around;
      overflow: hidden;
      @media (max-width: 768px) {
        width: 90%;
        overflow: hidden;
      }
    }
    .notification-content {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      align-items: center;
      gap: 15px;
    }
    .notification-img {
      border-radius: 5px;
      width: 256px;
      height: 191px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .notification-title {
      color: #143b76;
      font-family: DM Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 165%;
      letter-spacing: 0.24px;
      @media (max-width: 768px) {
        font-size: smaller;
      }
    }
    .notification-text {
      color: #4c5563;
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 165%;
      letter-spacing: 0.16px;
      word-wrap: break-word;
      @media (max-width: 768px) {
        font-size: smaller;
      }
    }
    .notification-toggle-collapse {
      color: #4277ff;
      text-align: right;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
      cursor: pointer;
      float: right;
      margin-top: 7px;
      border: 0px;
      background-color: #fff;
    }
    .notification-date {
      color: #9f9f9f;
      text-align: right;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.12px;
    }
    .center {
      text-align: center;
      margin-top: 10px;
    }
  }
}
