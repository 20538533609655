@import "base.scss";

.dashboard_sidebar_wrap {
  width: 100%;
  height: 100vh;
  border-right: 1px solid #dedede;
  padding-top: 40px;
  position: relative;
  .dashboard_sidebar_blocked {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: not-allowed;
    &:hover .dashboard_sidebar_blocked_text {
      display: block;
    }
    .dashboard_sidebar_blocked_text {
      display: none;
      position: absolute;
      width: 150px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      background-color: white;
      font-size: 12px;
      padding: 8px;
      line-height: normal;
      color: red;
      text-align: center;
      right: -50px;
    }
  }
  .dashboard_link_items_wrap {
    width: 100%;
    // border: none !important;
    .dashboard_link_item {
      width: 100%;
      height: 48px;
      color: #212121;
      font-size: 15px;
      padding-left: 10px;
      align-items: center;
      margin-bottom: 8px;
      @media (max-width: 991px) {
        // font-size: 13px;
      }
      .dashboard_link_item_image {
        margin-right: 14px;
      }
    }
  }
}
.active {
  position: relative;
  background: linear-gradient(133deg, #2b5fad 0%, #0b2d60 100%);
  color: white !important;
  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    background: linear-gradient(133deg, #73d9ff 0%, #0688b9 100%);
  }
}
