@import "base.scss";

.modal_buy_carb {
  background-color: #fff;
  padding: 38px;
  border-radius: 8px;
  box-shadow: 4px 2px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  .modal_buy_carb_title {
    margin-bottom: 40px;
  }
  .modal_buy_carb_close {
    width: 46px;
    height: 46px;
    position: absolute;
    right: 32px;
    top: 21px;
    cursor: pointer;
    @media (max-width: 767px) {
      width: 36px;
      height: 36px;
    }
  }
  .modal_buy_carb_info_wrap {
    background-color: #f8f8f8;
    padding: 20px;
    margin-bottom: 14px;
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .modal_buy_carb_info_initials {
      border-radius: 50%;
      background: #c8f0ff;
      width: 45px;
      height: 40px;
      margin-right: 23px;

      @media (max-width: 767px) {
        font-size: 12px;
        margin-right: 5px;
        width: 25px;
        height: 25px;
      }
    }
  }

  .modal_buy_carb_info_text_wrap {
    @media (max-width: 467px) {
      max-width: 230px;
    }

    .modal_buy_carb_info_text {
      align-items: center;
      margin-bottom: 12px;

      .modal_buy_carb_info_bold {
        margin-right: 17px;
        color: #212121;
        font-size: 20px;
        font-weight: 500;

        @media (max-width: 991px) {
          font-size: 15px;
        }
      }

      .modal_buy_carb_info_normal {
        color: #4c5563;
        font-size: 15px;
        padding-right: 10px;

        @media (max-width: 991px) {
          font-size: 12px;
        }

        @media (max-width: 767px) {
          font-size: 11px;
        }
      }

      .modal_buy_carb_info_borderb {
        border-right: 1px solid #4c5563;
        border-left: 1px solid #4c5563;
        padding: 0px 10px;

        @media (max-width: 767px) {
          padding: 0px 5px;
        }
      }

      .modal_buy_carb_info_borderr {
        // border-right: 1px solid #4c5563;
        padding: 0px 10px;

        @media (max-width: 767px) {
          padding: 0px 5px;
        }
      }
    }
  }
  .modal_buy_carb_sale_text {
    align-items: center;
    margin-bottom: 6px;

    .modal_buy_carb_sale_bold {
      margin-right: 17px;
      color: #5f41b2;
      text-align: center;
      font-size: 20px;

      @media (max-width: 991px) {
        font-size: 15px;
      }
    }

    .modal_buy_carb_sale_normal {
      color: #4c5563;
      font-size: 15px;
      padding-right: 10px;

      @media (max-width: 991px) {
        font-size: 12px;
      }

      @media (max-width: 767px) {
        font-size: 11px;
      }
    }
  }
  .modal_buy_carb_input_warp {
    border-radius: 8px;
    border: 1px solid #dedede;
    padding: 26px 0px;
    margin-top: 40px;
    .dashboard_table_tab {
      .dashboard_table_tab_item {
        color: #4c5563;
        text-align: center;
        font-size: 13px;
        font-weight: 700;
        line-height: 19.307px;
        border-bottom: 1px solid #d3d4d8;
        width: 50%;
        padding-bottom: 8px;
        cursor: pointer;

        @media (max-width: 991px) {
          font-size: 11px;
        }
      }

      .dashboard_table_tab_item_active {
        @extend .dashboard_table_tab_item;
        color: #115ccd;
        border-bottom: 2px solid #4277ff;
      }
    }
    .modal_buy_carb_input {
      padding: 30px;
      @media (max-width: 991px) {
        padding: 15px;
      }
      .modal_buy_carb_input_text_red {
        margin-bottom: 13px;
        color: #ff5151;
        font-size: 12px;
        @media (max-width: 991px) {
          font-size: 11px;
        }
      }
      .modal_buy_carb_input_text {
        color: $blue;
        font-size: 14px;
        margin-bottom: 10px;
        @media (max-width: 991px) {
          font-size: 12px;
        }
      }
      .modal_buy_carb_input_btn {
        margin-top: 30px;
      }
    }
  }
}
