@import "base.scss";

.messsage_list {
  padding-top: 17px;
  width: 100%;
  height: 100%;
  .messsage_list_search_wrap {
    padding: 5px;
  }
  .messsage_list_bg {
    background: #f8f8f8;
    margin-top: 4px;
    padding: 5px;

    // @media (max-width: 991px) {
    //   height: 100vh;
    // }
    .messsage_list_heading {
      margin-bottom: 11px;
      margin-top: 20px;
    }
    .messsage_list_wrap {
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #dedede;
      background: #fff;
      margin-bottom: 4px;
      .messsage_list_img_text_wrap {
        align-items: center;
        .messsage_list_img {
          margin-right: 12px;
          width: 49px;
          height: 49px;
        }
        .messsage_list_text {
          .messsage_list_last {
            color: #4c5563;
            font-size: 13px;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (max-width: 991px) {
              font-size: 11px;
              max-width: 180px;
            }
          }
        }
      }
    }
    .messsage_list_wrap_bg {
      background-color: #f2f5ff;
    }
    .messsage_list_new {
      color: white;
      font-size: 12px;
      background-color: $blue;
      width: 23px;
      height: 23px;
      border-radius: 50%;
    }
    .messsage_list_name {
      color: #212121;
      font-size: 14px;
      margin-bottom: 5px;

      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
    .messsage_list_name_time {
      @extend .messsage_list_name;
      font-size: 11px;
      @media (max-width: 991px) {
        font-size: 8px;
        white-space: nowrap;
      }
    }
  }
}
