@import "base.scss";

.view_message {
  padding: 13px;
  // @media (max-width: 991px) {
  //   height: 100vh;
  // }
  width: 100%;
  height: 100%;
  .view_message_cancel {
    display: none;
    position: fixed;
    top: 22%;
    background-color: white;
    border-radius: 8px;
    padding: 2px;
    @media (max-width: 767px) {
      display: block;
    }
  }
  .view_message_banner {
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    background: #f8f8f8;
    .view_message_banner_img {
      margin-right: 25px;
    }
    .view_message_banner_text {
      color: #192a3e;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.15px;
      margin-right: 23px;
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
    .view_message_banner_online {
      width: 8px;
      height: 8px;
      background-color: #00c4c4;
      border-radius: 50%;
    }
  }
  .view_message_wrap {
    flex-direction: column;
    width: 100%;

    .view_message_time {
      color: #4c5563;
      font-size: 12px;
      letter-spacing: 0.12px;
      border-radius: 5px;
      background: #d0ddff;
      padding: 3px 30px;
      margin-top: 16px;
      margin-bottom: 12px;
    }
    .view_message_all {
      width: 100%;
    }
  }
}
