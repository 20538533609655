@import "base.scss";

.my_account_info {
  .my_account_info {
    width: 100%;
    align-items: center;
    padding-top: 20px;
    @media (max-width: 991px) {
      flex-direction: column;
    }

    .my_account_info_avartar {
      width: 100px;
      height: 100px;
      flex-shrink: 0;
      border-radius: 50%;
      border: 5px solid #dcdddf;
      // background: #dcdddf;
      object-fit: cover;
      margin-right: 14px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
        margin-right: 0px;
      }
    }

    .my_account_info_detail_wrap_inner {
      .my_account_info_name {
        color: #143b76;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 20px;

        @media (max-width: 991px) {
          font-size: 18px;
          display: flex;
          justify-content: center;
        }
      }

      .my_account_info_wallet_wrap {
        .my_account_info_wallet_wrap_inner {
          margin-right: 17px;

          @media (max-width: 767px) {
            margin-right: 10px;
          }

          .my_account_info_wallet_text {
            color: #4c5563;
            font-size: 11px;
            font-weight: 400;
            margin-bottom: 8px;
            white-space: nowrap;

            @media (max-width: 767px) {
              //   font-size: 12px;
            }
          }

          .my_account_info_wallet_value {
            @extend .my_account_info_wallet_text;
            color: #212121;
          }
        }
      }
    }

    .my_account_info_detail_wrap {
      align-items: center;
      // justify-content: start;
      width: 100%;

      @media (max-width: 991px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      .my_account_info_btn_wrap {
        @media (max-width: 991px) {
          margin-top: 30px;
        }

        .my_account_info_btn {
          margin-left: 23px;
          @media (max-width: 991px) {
            margin: 0px 13px;
          }
        }
      }
    }
  }
  .my_account_info_item_container {
    margin-top: 45px;
    .my_account_info_item_edit_wrap {
      .my_account_info_item_title {
        margin-bottom: 14px;
      }
      .my_account_info_item_edit {
        cursor: pointer;
        margin-bottom: 14px;
        .edit_image {
          margin-left: 10px;
        }
      }
    }

    .my_account_info_item_wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 80px;
      padding: 46px;
      border-radius: 4px;
      border: 1px solid #dedede;
      @media (max-width: 1200px) {
        grid-gap: 40px;
      }
      @media (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
      }
      @media (max-width: 467px) {
        grid-gap: 20px;
      }
      .my_account_info_item {
        // width: 100%;
        .my_account_info_item_img {
          margin-right: 10px;
        }
        .my_account_info_item_text_wrap {
          .my_account_info_item_text {
            color: #4c5563;
            font-size: 15px;
            width: 100%;
            overflow-x: scroll;
            &::-webkit-scrollbar {
              height: 0.1px;
            }
            @media (max-width: 767px) {
              width: 250px;
            }
          }
        }
      }
      .noaccount{
        width: 100%;
        grid-column: 2;
        .noaccount_img{

        }
        .noaccount_text{
          text-align: center;
          color: #4C5563;
        }
      }
    }
  }
}
