@import "base.scss";
.auth_left_side {
  position: relative;
  width: 100%;
  height: 110vh;
  background-image: url("../../../../../public/assets/images/authbg.png");
  padding-bottom: 190px;
  padding-left: 109px;
  padding-right: 109px;
  padding-top: 190px;
  .auth_left_side_title {
    font-size: 35px;
    line-height: 46px;
    color: $blue;
    width: 100%;
    padding-bottom: 100px;
    @media (max-width: 991px) {
      font-size: 25px;
    }
  }
  .auth_left_side_title_bold {
    font-weight: 700;
  }
  .auth_left_side_text_wrap {
    background: linear-gradient(
      106.97deg,
      rgba(255, 255, 255, 0.26) 5.84%,
      rgba(255, 255, 255, 0.05) 70.7%
    );
    backdrop-filter: blur(100px);
    border-radius: 8px;
    height: 100%;
    width: 100%;
    padding-top: 80px;
    padding-left: 59px;
    font-size: 36px;
    color: $blue;
    font-weight: 700;
    z-index: 2;
    @media (max-width: 1200px) {
      padding-left: 20px;
    }
    @media (max-width: 991px) {
      font-size: 21px;
    }
  }
  .auth_left_side_text_light {
    font-weight: 400;
  }
  .auth_left_side_float {
    position: absolute;
    background: #0057ff;
    filter: blur(100px);
    height: 124px;
    width: 128px;
    right: 82px;
    bottom: 100px;
    border-radius: 50%;
    animation: floatAnimation 15s infinite ease-in-out;
  }
  .auth_left_side_float_one {
    @extend .auth_left_side_float;
    left: 80px;
    bottom: 50px;
    background: #00ffc2;
    animation: floatAnimation_one 10s infinite ease-in-out;
  }
  .auth_left_side_float_two {
    @extend .auth_left_side_float;
    top: 180px;
    right: 40%;
    background: #ff00f5;
    animation: floatAnimation_two 12s infinite ease-in-out;
  }
}

// Animations
@keyframes floatAnimation {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-90vh);
  }

  100% {
    transform: translate(0%);
  }
}

@keyframes floatAnimation_one {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(20vw);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes floatAnimation_two {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-20vw);
  }

  100% {
    transform: translateX(0%);
  }
}
