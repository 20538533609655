@import "base.scss";

.dashboard_home {
  width: 100%;
  .wallet_project_wrap {
    width: 100%;
    align-items: flex-start;
    margin-top: 64px;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    .wallet_project_wrap_left {
      width: 49%;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    .wallet_project_wrap_right {
      width: 49%;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }
  .dashboard_home_chart_wrap {
    margin-top: 40px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #dedede;
    padding: 28px;
    .dashboard_home_chart {
      overflow-x: scroll;
      .dashboard_home_chart_width {
        @media (max-width: 476px) {
          width: 500px;
        }
      }
    }
  }
}
