@import "base.scss";

.track_project {
  padding-top: 38px;
  position: relative;

  .track_project_wrap {
    padding-top: 20px;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .track_project_btn_wrap {
      .track_project_title {
        color: #143b76;
        font-size: 20px;
        font-weight: 500;

        @media (max-width: 991px) {
          font-size: 14px;
        }

        @media (max-width: 767px) {
          margin-bottom: 15px;
        }
      }
    }

    .track_project_search {
      // margin-top: -35px;
      position: absolute;
      right: 40px;
      top: 25px;

      @media (max-width: 767px) {
        position: unset;
      }
    }
  }

  .track_project_tab {
    margin-top: 20px;
    margin-bottom: 22px;

    .track_project_tab_item {
      color: #4c5563;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      line-height: 19.307px;
      border-bottom: 1px solid #4c5563;
      width: 50%;
      padding-bottom: 8px;
      cursor: pointer;

      @media (max-width: 991px) {
        font-size: 11px;
      }
    }

    .track_project_tab_item_active {
      @extend .track_project_tab_item;
      color: #115ccd;
      border-bottom: 2px solid #115ccd;
    }
  }

}
