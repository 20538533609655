@import "base.scss";

.dashboard_wallet {
  width: 100%;
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
  .dashboard_wallet_heading {
    color: #143b76;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 14px;
    }
  }
  .dashboard_wallet_wrap {
    border-radius: 8px;
    border: 1px solid #dedede;
    background: #fff;
    padding: 27px;
    height: 196px;
    position: relative;
    overflow: hidden;
    .dashboard_wallet_pattern {
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
      width: 70%;
      height: 100%;
      z-index: 1;
    }
    .dashboard_wallet_patterntwo {
      @extend .dashboard_wallet_pattern;
      left: 37%;
      width: 100%;
      transform: rotate(60deg);
    }
    .dashboard_wallet_title_wrap {
      .dashboard_wallet_title_icon_wrap {
        align-items: center;
        .dashboard_wallet_title {
          color: #4c5563;
          font-size: 14px;
          font-weight: 500;
          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
        .dashboard_wallet_icon {
          margin-left: 21px;
          background: rgba(241, 241, 241, 0.5);
          padding: 4px;
          cursor: pointer;
          transition: all 0.3s linear;
          z-index: 2;
          &:hover {
            background: rgba(227, 226, 226, 0.5);
          }
        }
      }
    }
    .dashboard_wallet_value {
      color: $blue;
      font-size: 20px;
      font-weight: 400;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
}
