.notification-container {
  position: relative;
  cursor: pointer;

  .dashboard_icon {
    transition: all 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .notification-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 250px;
    max-height: 250px;
    overflow-y: auto;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }

    .notification-item,
    a.notification-item {
      display: block;
      padding: 8px 12px;
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid #eee; // Line demarcation for each item
      font-size: small;
      word-wrap: break-word; // Breaks long words and wraps them.
      overflow: hidden; // Makes sure any content exceeding the box boundaries is hidden.
      max-width: 250px;
      &:hover {
        background-color: #f7f7f7;
        text-decoration: none;
      }

      &:last-child {
        border-bottom: 1px solid #eee; // Ensure the last item also has the border
      }
    }

    .notif-date {
      font-size: smaller;
      float: right;
    }
  }
}
