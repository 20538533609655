@import "base.scss";
.upload {
  position: relative;
  padding: 39px 58px 33px 64px;
  border-radius: 8px;
  border: 1px solid #dedede;
  width: 100%;

  @media (max-width: 991px) {
    padding: 10px 10px 10px 10px;
  }
  .upload_image_wrap {
    background-color: #e4ddf4;
    width: 60px;
    height: 60px;
    margin-bottom: 29px;
    border-radius: 50%;

    @media (max-width: 991px) {
      width: 50px;
      height: 50px;
    }
  }

  .upload_text_bold {
    color: #212121;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    text-align: center;
    @media (max-width: 991px) {
      font-size: 14px;
    }
  }

  .upload_text {
    color: #4c5563;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    @media (max-width: 991px) {
      font-size: 12px;
    }
  }

  .uplaod_input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.upload_progress_wrap {
  border-radius: 8px;
  border: 1px dashed #a1a4b1;
  margin-top: 20px;
  padding: 18px;

  .upload_progress {
    width: 100%;

    .upload_progress_text {
      color: #212121;
      font-size: 18px;
      font-weight: 500;
      line-height: 150%;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }

    .upload_progress_size {
      color: #4c5563;
      font-size: 14px;
      line-height: 150%;
    }

    .upload_progress_inner_wrap {
      .upload_progress {
        background-color: #dedede;
        width: 100%;
        height: 9px;
        border-radius: 38px;
      }

      .upload_progress_track {
        @extend .upload_progress;
        background-color: #4277ff;
        transition: all 1s linear;
      }

      .upload_progress_trash {
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
