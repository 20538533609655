@import "base.scss";

.registry_table {
  overflow-x: scroll;
  .registry_table_wrap {
    margin-top: 30px;
    min-width: 750px;
    width: 100%;

    .registry_table_head {
      border-radius: 6.436px;
      background: #d0ddff;
      padding: 12px 19px;

      .registry_table_head_item {
        color: #212121;
        font-size: 11.263px;
        // font-family: Inter;
        font-weight: 500;
        line-height: 16.09px;
        width: 130px;

        @media (max-width: 991px) {
          width: 90px;
        }
      }
    }

    .registry_table_body {
      padding: 12px 19px;
      cursor: pointer;
      transition: all 0.3 linear;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      .registry_table_body_item {
        text-align: left;
        width: 130px;
        color: #4c5563;
        overflow-x: scroll;
        font-size: 11.263px;

        &::-webkit-scrollbar {
          background: transparent;
          width: 0;
          height: 0;
        }

        // font-family: Inter;
        font-weight: 400;
        line-height: 16.09px;
        white-space: nowrap;

        @media (max-width: 991px) {
          width: 90px;
        }
      }

      .registry_table_body_item_completed {
        border-radius: 10px;
        background: #d1fae5;
        padding: 2px 10px;
        color: #065f46;
      }

      .registry_table_body_item_pending {
        @extend .registry_table_body_item_completed;
        background: #fef3c7;
        color: #92400e;
      }

      .registry_table_body_item_failed {
        @extend .registry_table_body_item_completed;
        background: #fee2e2;
        color: #991b1b;
      }
    }

    .registry_table_body_bg {
      background-color: #f8f8f8;
    }
  }
}

.registry_search {
  padding-bottom: 20px;

  .registry_search_img {
    margin-bottom: 10px;
  }

  .registry_search_head {
    color: #143b76;
    font-size: 36px;
    text-align: center;

    @media (max-width: 991px) {
      font-size: 18px;
    }
  }

  .registry_search_text {
    margin-bottom: 38px;
    text-align: center;
  }

  .registry_search_input {
    max-width: 700px;
    width: 100%;
  }
}
