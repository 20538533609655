@import "base.scss";

.trans_db_table {
  .trans_db_table_search {
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .trans_db_table_title {
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .trans_db_table_search_wrap {
      align-items: center;

      .trans_db_table_search_item {
        margin-left: 10px;

        .trans_db_table_search_btn {
          background-color: $purple;
        }

        .trans_db_table_input {
          margin-bottom: 0px;
          // border: 1px solid #DEDEDE;
        }
      }
    }
  }

  .trans_db_table_tab {
    margin-top: 21px;

    .trans_db_table_tab_item {
      color: #4c5563;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      line-height: 19.307px;
      border-bottom: 1px solid #d3d4d8;
      width: 50%;
      padding-bottom: 8px;
      cursor: pointer;

      @media (max-width: 991px) {
        font-size: 11px;
      }
    }

    .trans_db_table_tab_item_active {
      @extend .trans_db_table_tab_item;
      color: #115ccd;
      border-bottom: 2px solid #4277ff;
    }
  }

  .trans_db_table_wrap {
    margin-top: 30px;
    width: 100%;
    overflow-x: scroll;

    .trans_db_table {
      width: 100%;
      min-width: 767px;

      .trans_db_table_head {
        border-radius: 6.436px;
        background: #d0ddff;
        padding: 12px 19px;

        .trans_db_table_head_item {
          color: #212121;
          font-size: 11.263px;
          // font-family: Inter;
          font-weight: 500;
          line-height: 16.09px;
          width: 130px;

          @media (max-width: 991px) {
            width: 90px;
          }
        }
      }

      .trans_db_table_body {
        padding: 12px 19px;
        cursor: pointer;
        transition: all 0.3 linear;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }

        .trans_db_table_body_item {
          text-align: left;
          width: 120px;
          color: #4c5563;
          overflow-x: scroll;
          font-size: 11.263px;

          &::-webkit-scrollbar {
            background: transparent;
            width: 0;
            height: 0;
          }

          // font-family: Inter;
          font-weight: 400;
          line-height: 16.09px;
          white-space: nowrap;

          @media (max-width: 991px) {
            width: 90px;
          }
        }

        .trans_db_table_body_item_completed {
          border-radius: 10px;
          background: #d1fae5;
          padding: 2px 10px;
          color: #065f46;
        }

        .trans_db_table_body_item_pending {
          @extend .trans_db_table_body_item_completed;
          background: #fef3c7;
          color: #92400e;
        }

        .trans_db_table_body_item_failed {
          @extend .trans_db_table_body_item_completed;
          background: #fee2e2;
          color: #991b1b;
        }
      }

      .trans_db_table_body_bg {
        background-color: #f8f8f8;
      }
    }
  }
}
