@import "base.scss";

.input {
  width: 100%;
  // max-width: 469px;
  margin-bottom: 20px;
  .input_label_wrap {
    .input_label_wrap_text {
      font-size: 15px;
      color: $blue;
      margin-bottom: 8px;
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
    .input_label_wrap_text_required {
      @extend .input_label_wrap_text;
      color: #ff0707;
    }
    .tooltip {
      cursor: pointer;
      position: relative;
      color: white;
      font-size: 8px;
      z-index: 2;
      &:hover .tooltip_pop_up {
        opacity: 1;
        display: block;
      }
      .tooltip_pop_up {
        position: absolute;
        opacity: 0;
        display: none;
        background: black;
        padding: 5px;
        right: -45px;
        border-radius: 5px;
        min-width: 100px;
        top: 25px;
        text-align: center;
        &::before {
          content: "";
          position: absolute;
          background-color: black;
          height: 10px;
          width: 10px;
          transform: rotate(40deg);
          right: 48px;
          top: -5px;
        }
      }
    }
  }
  .input_main_wrap {
    position: relative;
    border-radius: 4px;
    border: 1px solid #a1a4b1;
    height: 38px;
    width: 100%;
    .input_main {
      position: relative;
      border: 1px solid transparent;
      border-radius: 4px;
      outline: none;
      padding: 3px;
      height: 34px;
      width: 90%;
      font-family: "DM Sans", sans-serif;
      color: $blue;
      font-size: 15px;
      &::placeholder {
        font-family: "DM Sans", sans-serif;
        color: #a1a4b1;
        font-size: 15px;
        @media (max-width: 991px) {
          font-size: 11px;
        }
      }
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
    .datepicker {
      @extend .input_main;
      width: 250%;
      @media (max-width: 991px) {
        width: 200%;
      }
    }
    .date {
      position: absolute;
      background-color: #f1f1f1;
      padding: 9px;
      right: 0;
      top: 0;
      height: 99%;
      cursor: pointer;
    }
  }
  
}
