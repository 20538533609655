@import "base.scss";

.dashboard_project_review {
  margin-top: 43px;
  width: 100%;
  overflow-x: scroll;
  .dashboard_project_table {
    width: 100%;
    min-width: 767px;

    .dashboard_project_table_head {
      border-radius: 6.436px;
      background: #d0ddff;
      padding: 12px 19px;
      .dashboard_project_table_head_item {
        color: #212121;
        font-size: 11.263px;
        // font-family: Inter;
        font-weight: 500;
        line-height: 16.09px;
        width: 130px;
        @media (max-width: 991px) {
          width: 90px;
        }
      }
    }

    .dashboard_project_table_body {
      padding: 12px 19px;
      .dashboard_project_table_body_item {
        text-align: left;
        width: 130px;
        color: #4c5563;
        overflow-x: scroll;
        font-size: 11.263px;
        &::-webkit-scrollbar {
          background: transparent;
          width: 0;
          height: 0;
        }
        // font-family: Inter;
        font-weight: 400;
        line-height: 16.09px;
        white-space: nowrap;
        @media (max-width: 991px) {
          width: 90px;
        }
      }
    }
    .dashboard_project_table_body_bg {
      background-color: #f8f8f8;
    }
  }
}
