@import "base.scss";

.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(5px);
  background-color: rgba(87, 87, 87, 0.2);
  scale: 1;
  padding: 10px;
  overflow-y: scroll;
  .modal_content_wrap {
    padding: 38px;
    border-radius: 8px;
    box-shadow: 4px 2px 15px rgba(0, 0, 0, 0.1);
    background-color: white;
    .modal_content_title {
      font-size: 40px;
      line-height: 34px;
      color: $blue;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 991px) {
        font-size: 25px;
      }
    }
    .modal_message_text {
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 60px;
      color: #575757;
      max-width: 400px;
      text-align: center;
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
    .modal_content_image {
      width: 150px;
      margin-bottom: 30px;
      animation: modalImage 1s ease-in;
    }
  }
}

.modal_close {
  @extend .modal;
  // opacity: 0;
  // scale: 0.1;
  display: none;
  z-index: -10;
  transition: all 3 linear;
  opacity: 0;
}


@keyframes modalImage {
  50% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}