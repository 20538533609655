@import "base.scss";

.dashboard_staff {
  background: #f8f8f8;
  padding: 30px;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    padding: 20px;
  }
  .dashboard_staff_banner_wrap {
    align-items: flex-end;
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .dashboard_staff_banner_text_wrap {
      align-items: flex-end;
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
      .dashboard_staff_banner_text_staff {
        margin-right: 40px;
        .dashboard_staff_banner_text_staff_title {
          color: $blue;
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 15px;
          @media (max-width: 991px) {
            font-size: 18px;
          }
        }
        .dashboard_staff_banner_text_staff_total {
          color: #4277ff;
          font-size: 15px;
          margin-bottom: 8px;
          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
        .dashboard_staff_banner_text_staff_value {
          color: #212121;
          font-size: 15px;
          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
      }
      .dashboard_staff_banner_text_org {
        .dashboard_staff_banner_text_org_title {
          color: #4277ff;
          font-size: 15px;
          margin-bottom: 8px;
          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
        .dashboard_staff_banner_text_org_value {
          color: #212121;
          font-size: 15px;
          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
      }
    }
    .dashboard_staff_banner_inputbtn_wrap {
      @media (max-width: 767px) {
        flex-direction: column;
        width: 100%;
      }

      .dashboard_staff_banner_input_wrap {
        width: 300px;
        margin-right: 28px;
        @media (max-width: 991px) {
          margin-bottom: 40px;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      .dashboard_staff_banner_btn {
        max-width: 180px;
      }
    }
  }
}
.dashboard_staff_wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;
  margin-top: 14px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.dashboard_staff_form_modal {
  position: fixed;
  background: rgba(113, 113, 113, 0.15);
  backdrop-filter: blur(11px);
  width: 100%;
  height: 102vh;
  z-index: 3;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  .dashboard_staff_form_modal_wrap {
    background-color: #fff;
    width: 40%;
    padding-top: 33px;
    padding-bottom: 64px;
    padding-left: 60px;
    padding-right: 60px;
    @media (max-width: 991px) {
      width: 70%;
    }
    @media (max-width: 767px) {
      width: 90%;
    }
    .dashboard_staff_form_modal_title {
      font-size: 24px;
      font-weight: 500;
      color: $blue;
      margin-bottom: 29px;

      @media (max-width: 991px) {
        font-size: 18px;
      }
    }
  }
}

.dashboard_staff_form_modal_close {
  z-index: -10;
  opacity: 0;
}
