@import "base.scss";

.profile {
  background-color: #fff;
  padding-top: 78px;
  padding-left: 91px;
  padding-right: 91px;
  padding-bottom: 29px;
  border-radius: 8px;
  box-shadow: 4px 2px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 50%;

  @media (max-width: 991px) {
    padding-top: 28px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 29px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  .profile_title {
    margin-bottom: 40px;
  }

  .profile_close {
    width: 46px;
    height: 46px;
    position: absolute;
    right: 32px;
    top: 21px;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 36px;
      height: 36px;
    }
  }

  .profile_info_wrap {

    .profile_input_img {
      width: 134px;
      height: 134px;
      object-fit: cover;
      margin-bottom: 15px;
      border-radius: 50%;
      @media (max-width: 991px) {
        width: 100px;
        height: 100px;
      }
    }
    .profile_input_wrap {
      position: relative;
      .profile_input_text {
        border-radius: 8px;
        cursor: pointer;
        border: 1px solid #dedede;
        background: #fff;
        padding: 2px 15px;
      }
      .profile_input {
        position: absolute;
        opacity: 0;
        left: 0;
        width: 100%;
      }
    }

    .profile_info_text {
      align-items: center;
      margin-bottom: 12px;
      color: #4c5563;
      font-size: 20px;

      @media (max-width: 991px) {
        font-size: 17px;
      }
    }
  }

  .profile_sale_text {
    align-items: center;
    margin-bottom: 6px;

    .profile_sale_bold {
      margin-right: 17px;
      color: #5f41b2;
      text-align: center;
      font-size: 50px;

      @media (max-width: 991px) {
        font-size: 15px;
      }
    }

    .profile_sale_normal {
      color: #4c5563;
      font-size: 15px;
      padding-right: 10px;

      @media (max-width: 991px) {
        font-size: 12px;
      }

      @media (max-width: 767px) {
        font-size: 11px;
      }
    }
  }

  .profile_input_warp {
    margin-top: 30px;

    .profile_input {
      .profile_input_item {
        .profile_input_item_fee {
          margin-top: -20px;
          margin-bottom: 25px;
          color: #4c5563;
          font-size: 15px;

          @media (max-width: 991px) {
            font-size: 12px;
          }
        }
      }

      .profile_input_text {
        color: $blue;
        font-size: 14px;
        margin-bottom: 10px;

        @media (max-width: 991px) {
          font-size: 12px;
        }
      }

      .profile_input_btn_wrap {
        .profile_input_btn {
          margin-top: 30px;
          max-width: 140px;
          margin-left: 16px;
        }

        .profile_input_btn_two {
          @extend .profile_input_btn;
          background-color: #fff;
          border-radius: 26px;
          border: 1px solid #4c5563;
          color: #4c5563;
        }
      }
    }
  }
}
