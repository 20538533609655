@import "base.scss";

.credit {
  border-radius: 8px;
  background: #f8f8f8;
  width: 100%;
  align-items: center;
  padding: 30px;

  @media (max-width: 991px) {
    padding: 20px;
    flex-direction: column;
  }

  .credit_avartar {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 80px;
    background: #dcdddf;
    object-fit: cover;
    margin-right: 14px;
    border: 5px solid #dcdddf;

    @media (max-width: 767px) {
      margin-bottom: 20px;
      margin-left: 0px;
    }
  }
  .credit_detail_wrap_inner {
    .credit_name {
      color: #143b76;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;

      @media (max-width: 991px) {
        font-size: 18px;
        display: flex;
        justify-content: center;
      }
    }

    .credit_wallet_wrap {
      .credit_wallet_wrap_inner {
        margin-right: 17px;

        @media (max-width: 767px) {
          margin-right: 10px;
        }

        .credit_wallet_text {
          color: #4c5563;
          font-size: 11px;
          font-weight: 400;
          margin-bottom: 8px;
          white-space: nowrap;

          @media (max-width: 767px) {
            //   font-size: 12px;
          }
        }

        .credit_wallet_value {
          @extend .credit_wallet_text;
          color: #212121;
        }
      }
    }
  }

  .credit_detail_wrap {
    align-items: center;
    // justify-content: start;
    width: 100%;

    @media (max-width: 991px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .credit_btn_wrap {
      @media (max-width: 991px) {
        margin-top: 30px;
      }

      .credit_btn_pending {
        opacity: 0.8;
        cursor: not-allowed;
      }

      .credit_btn_one {
        margin-right: 15px;
        margin-bottom: 15px;
        color: #fff;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        width: 200px;

        @media (max-width: 1300px) {
          width: 100%;
        }

        @media (max-width: 991px) {
          font-size: 12px;
        }
      }

      .credit_btn_two {
        @extend .credit_btn_one;
        background-color: #5f41b2;
      }

      .credit_btn_three {
        @extend .credit_btn_one;
        background-color: #fff;
        margin-bottom: 0px;
        color: #4c5563;
        border-radius: 26px;
        border: 1px solid #4c5563;
      }
    }
  }
}
