@import "base.scss";

.registry_table {
  .registry_table_wrap {
    margin-top: 30px;
    width: 100%;
    overflow-x: scroll;

    .registry_table {
      width: 100%;
      min-width: 467px;

      .registry_table_head {
        border-radius: 6.436px;
        background: #d0ddff;
        padding: 12px 19px;

        .registry_table_head_item {
          color: #212121;
          font-size: 11.263px;
          // font-family: Inter;
          font-weight: 500;
          line-height: 16.09px;
          width: 130px;

          @media (max-width: 991px) {
            width: 90px;
          }
        }
      }

      .registry_table_body {
        padding: 12px 19px;
        cursor: pointer;
        transition: all 0.3 linear;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }

        .registry_table_body_item {
          text-align: left;
          width: 130px;
          color: #4c5563;
          overflow-x: scroll;
          font-size: 11.263px;

          &::-webkit-scrollbar {
            background: transparent;
            width: 0;
            height: 0;
          }

          // font-family: Inter;
          font-weight: 400;
          line-height: 16.09px;
          white-space: nowrap;

          @media (max-width: 991px) {
            width: 90px;
          }
        }

        .registry_table_body_item_completed {
          border-radius: 10px;
          background: #d1fae5;
          padding: 2px 10px;
          color: #065f46;
        }

        .registry_table_body_item_pending {
          @extend .registry_table_body_item_completed;
          background: #fef3c7;
          color: #92400e;
        }

        .registry_table_body_item_failed {
          @extend .registry_table_body_item_completed;
          background: #fee2e2;
          color: #991b1b;
        }
      }

      .registry_table_body_bg {
        background-color: #f8f8f8;
      }
    }
  }
}
