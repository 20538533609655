@import "base.scss";

.dashboard_header {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  background-color: #fff;
  padding: 24px 30px;
  border-bottom: 1px solid #dedede;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .dashboard_header_logo {
    font-size: 35px;
    color: $blue;
    @media (max-width: 991px) {
      font-size: 20px;
    }
    .dashboard_header_logo_img {
      width: 40px;
      margin-right: 9px;
    }
    
  }
  .dashboard_header_logo_bold {
    font-size: 15px;
    
      @media (max-width: 991px) {
        font-size: 13px;
      }
    font-weight: 700;
  }
  .dashboard_header_textbtn_wrap {
    @media (max-width: 991px) {
      font-size: 12px;
      display: none;
    }
    .dashboard_header_text {
      color: #4c5563;
      font-size: 15px;
      margin-right: 14px;
      white-space: nowrap;
    }

    .dashboard_avartar {
      margin-right: 14px;
      object-fit: cover;
      width: 50px;
      height: 50px;
      border: 3px solid #dcdddf;
      // background: #dcdddf;
      border-radius: 50%;
      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .dashboard_icon {
    margin-left: 20px;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }
  .dashboard_header_menu {
    display: none;
    @media (max-width: 991px) {
      display: block;
      margin-left: 22px;
    }
  }
}
