@import "base.scss";

.send_message {
  position: relative;
  padding: 10px;
  // @media (max-width: 991px) {
  //   padding: 20px 10px 10px 10px;
  // }
  .send_message_input {
    width: 70%;
  }
  .send_message_attach_wrap {
    width: 10%;
    position: relative;
    overflow: hidden;
    .send_message_attach {
      opacity: 0;
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }
    .send_message_attach_img {
      z-index: 1;
      width: 40px;
      height: 40px;
      cursor: pointer;
      @media (max-width: 991px) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .send_message_upload {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
  }
  .send_message_btn {
    width: 20%;
  }
}
