@import "base.scss";

.message_item {
  width: 100%;
  .message_item_file_send {
    padding: 5px;
    align-items: center;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border: #d0cdcd 1px solid;
    .message_item_file_text {
      color: $blue;
      font-size: 14px;
      letter-spacing: 0.16px;
      max-width: 200px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 0.1px;
      }
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
    .message_item_file_img {
      width: 50px;
    }
  }
  .message_item_file_recieve {
    @extend .message_item_file_send;
    // background: #fff;
    border: #f3f0f0 0px solid;
    .message_item_file_text {
      color: $blue;
      font-size: 14px;
      letter-spacing: 0.16px;
      max-width: 200px;
      overflow-x: scroll;
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
  }
  .message_item_img {
    width: 14px;
    height: 14px;
    margin-right: 15px;
  }
  .message_item_img_send {
    @extend .message_item_img;
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .message_item_text_recieve {
    border-radius: 0px 24px 24px 24px;
    background: linear-gradient(133deg, #2b5fad 0%, #0b2d60 100%);
    width: fit-content;
    padding: 12px 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    max-width: 85%;
    .message_item_message {
      color: #fff;
      font-size: 14px;
      letter-spacing: 0.16px;
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
    .message_item_timestatus {
      align-items: center;
      width: 100%;
      .message_item_time {
        color: #d0cdcd;
        text-align: right;
        font-size: 10px;
        letter-spacing: 0.12px;
        margin-right: 5px;
        margin-left: 5px;
        font-size: 8px;
        white-space: nowrap;
        @media (max-width: 991px) {
          font-size: 9px;
        }
      }
      .message_item_read {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #9f9f9f;
      }
      .message_item_unread {
        @extend .message_item_read;
        background-color: green;
      }
    }
  }
  .message_item_text_send {
    border-radius: 24px 24px 0px 24px;
    border: 1px solid #dedede;
    background: #fff;
    max-width: 85%;
    width: fit-content;
    padding: 12px 20px;
    margin-bottom: 2px;
    .message_item_message {
      color: $blue;
      font-size: 14px;
      letter-spacing: 0.16px;
      // width: 750%;
      // background-color: red;
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
    .message_item_timestatus {
      align-items: center;
      width: 100%;

      .message_item_time {
        color: #9f9f9f;
        text-align: right;
        letter-spacing: 0.12px;
        margin-right: 5px;
        margin-left: 5px;
        font-size: 8px;
        white-space: nowrap;
        text-align: left;
        @media (max-width: 991px) {
          font-size: 9px;
        }
      }

      .message_item_read {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #9f9f9f;
      }

      .message_item_unread {
        @extend .message_item_read;
        background-color: green;
      }
    }
  }
}
