@import "base.scss";

.dashboard_staff_card {
  border-radius: 8px;
  border: 1px solid #dedede;
  padding-top: 28px;
  padding-bottom: 65px;
  padding-left: 28px;
  padding-right: 28px;
  position: relative;
  .dashboard_staff_card_detail_wrap {
    align-items: flex-start;
    position: relative;
    .dashboard_staff_card_detail_options_open {
      border-radius: 8px;
      box-shadow: 0px 2px 11px -2px rgba(62, 67, 69, 0.25);
      background: white;
      position: absolute;
      width: 87%;
      z-index: 2;
      padding: 10px;
      //   transition: all 0.1s linear;
      .dashboard_staff_card_detail_options_wrap {
        padding: 12px 20px;
        align-items: center;
        border-bottom: 1px solid #dcdcdc;
        cursor: pointer;
        .dashboard_staff_card_detail_options_img {
          margin-right: 7px;
        }
      }
    }
    .dashboard_staff_card_detail_options_close {
      opacity: 0;
      z-index: -10;
    }
    .dashboard_staff_card_detail {
      align-items: center;
      margin-bottom: 28px;
      .dashboard_staff_card_detail_img {
        width: 54px;
        height: 54px;
        margin-right: 16px;
        @media (max-width: 991px) {
          width: 44px;
          height: 44px;
        }
      }
      .dashboard_staff_card_detail_namepos {
        .dashboard_staff_card_detail_name {
          font-size: 15px;
          color: $blue;
          //   font-family: Manrope;
          font-weight: 600;
          line-height: 23.968px;
          @media (max-width: 991px) {
            font-size: 13px;
          }
        }
        .dashboard_staff_card_detail_position {
          color: #212121;
          font-size: 12px;
          // font-family: Manrope;
          font-weight: 400;
          line-height: 17.968px;
          @media (max-width: 991px) {
            font-size: 10px;
          }
        }
      }
    }

    .dashboard_staff_card_detail_options {
      border-radius: 4px;
      border: 1px solid #dcdcdc;
      padding: 5px;
      cursor: pointer;
      .dashboard_staff_card_detail_options_img {
      }
      .dashboard_staff_card_detail_options_text {
        color: #575757;
        font-size: 15px;
        line-height: 23.968px;
        @media (max-width: 991px) {
          font-size: 12px;
        }
      }
    }
  }
  .dashboard_staff_card_contact {
    align-items: center;
    margin-bottom: 7px;

    .dashboard_staff_card_contact_img {
      margin-right: 8px;

      @media (max-width: 991px) {
      }
    }

    .dashboard_staff_card_contact_namepos {
      .dashboard_staff_card_contact_name {
        color: #4c5563;
        font-size: 13px;
        // font-family: Manrope;
        font-style: normal;
        font-weight: 400;
        line-height: 23.968px;

        @media (max-width: 991px) {
          font-size: 10px;
        }
      }

      .dashboard_staff_card_contact_position {
        color: #212121;
        font-size: 14px;
        // font-family: Manrope;
        font-weight: 400;
        line-height: 17.968px;

        @media (max-width: 991px) {
          font-size: 12px;
        }
      }
    }
  }
  .dashboard_staff_card_badge {
    position: absolute;
    right: 20px;
    bottom: 24px;
  }
  .dashboard_staff_loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    border-radius: 8px;
  }
}
