@import "base.scss";

.steps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding-top: 50px;
  padding-bottom: 100px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  .text_wrap {
    grid-column: span 2;
    .grad_text {
      margin-bottom: 18px;
    }
  }
  .steps_text {
    max-width: 468px;
  }
}
