@import "base.scss";

.otp_input {
  border: 1px solid #a1a4b1;
  border-radius: 4px;
  width: 50px;
  height: 50px;
  font-size: 24px;
  padding-left: 5%;
  margin: 5px;
  color: $blue;
  @media (max-width: 991px) {
    width: 35px;
    height: 35px;
    padding-left: 4.7%;
    font-size: 15px;
  }
}
