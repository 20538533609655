* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "DM Sans", sans-serif;
  scroll-behavior: smooth;
}
