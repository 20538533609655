@import "base.scss";

.footer {
  background: #072553;
  padding-top: 80px;
  .footer_wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 100px;
    align-items: center;
    gap: 80px;
    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .footer_wrap_logo_con {
      .footer_wrap_logo {
        margin-bottom: 25px;
        align-items: center;
        .footer_logo_img {
          margin-right: 9px;
        }
        .footer_head {
          color: #fff;
          font-size: 35px;
          font-weight: 700;
          @media (max-width: 991px) {
            font-size: 24px;
          }
        }
      }
      .footer_wrap_logo_text {
        color: #dedede;
        font-size: 18px;
        line-height: 28.08px;
        @media (max-width: 991px) {
          font-size: 13px;
        }
      }
    }
    .footer_wrap_list {
      .footer_wrap_list_item_head {
        color: #fff;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        margin-bottom: 12px;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
      .footer_wrap_list_item {
        @extend .footer_wrap_list_item_head;
        text-transform: capitalize;
        color: #a7a8b4;
      }
      .footer_input_wrap {
        border-radius: 40px;
        border: 1px solid #9f9f9f;
        padding: 10px;
        margin-top: 13px;
        .footer_input {
          background: transparent;
          border: none;
          outline: none;
          height: 100%;
          font-size: 14px;
          color: #fff;
          font-family: DM Sans;
          @media (max-width: 991px) {
            width: 60%;
          }
          @media (max-width: 767px) {
            width: 65%;
          }
          &::placeholder {
            color: #748093;
            font-size: 14px;
          }
        }
        .footer_btn {
          max-width: 126px;
          border: 1px solid #fff;
          background: rgba(255, 255, 255, 0.08);
        }
      }
    }
  }
  .footer_terms {
    padding: 10px 0px;
    background: #052048;
    color: #dedede;
    font-size: 16px;
    line-height: 28.08px;
    @media (max-width: 991px) {
      font-size: 14px;
    }
  }
}
