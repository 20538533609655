@import "base.scss";

.dashboard_wallet_banner {
  background-color: #f8f8f8;
  padding: 50px;
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 467px) {
      padding: 10px;
  }
  .dashboard_wallet_banner_title {
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  .dashboard_wallet_banner_info_wrap {
    .dashboard_wallet_banner_info {
      margin-left: 40px;
      .dashboard_wallet_banner_info_title {
        color: #4277ff;
        font-size: 15px;
        margin-bottom: 8px;
        @media (max-width: 991px) {
          font-size: 12px;
        }
      }
      .dashboard_wallet_banner_info_value {
        @extend .dashboard_wallet_banner_info_title;
        color: #212121;
      }
    }
  }
}
